import * as React from 'react'
import CharismaExaminatorExamsList from '../exams/examinatorExamsList.tsx'
import CharismaTutorSpace from '../tutors/tutorSpace.tsx';
import Grid from '@mui/material/Grid'

import { useParams} from 'react-router-dom';    

export default function CharismaWelcomeTutor(props){
    
    let params = useParams();
    const lExamId: number =  parseInt(params['examId']);
    const lPage: string =  params['page'];
    
    
    return(
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <CharismaExaminatorExamsList
                    examinatorId={props.examinatorId}
                    studentTrainingId = {lExamId}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                {lExamId !== undefined && !isNaN(lExamId)                               

                } 
            </Grid>
        </Grid>
  
    );
}