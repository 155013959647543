import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';


import { ValidateString } from '../utils/Validator.tsx';

export default function CharismaEvaluationTemplateItemForm(props){
    
    const [title, setTitle] = React.useState(props.item !== undefined ? props.item.title : "");
    const [comment, setComment] = React.useState(props.item !== undefined ? props.item.comment : "");
    const [type, setType] = React.useState(props.item !== undefined ? props.item.type : 4);
    const [nError, setNError] = React.useState(0);
    const [wait, setWait] = React.useState(false);


    React.useEffect(()=>{
        setTitle(props.item !== undefined ? props.item.title : "");
        setComment(props.item !== undefined ? props.item.comment : "");
        setType(props.item !== undefined ? props.item.type : 4);

    }, [props.item])
    
    
    function handleSubmit(){
        const lValidName: boolean = title.trim().length > 0;
        if(!lValidName){
            setNError(1);
        }                       
        if(lValidName && props.id === undefined){
            setWait(false);
            props.handleSubmit(title, comment, type, props.parentId);
        }
        else if(lValidName){
            setWait(false);
            props.handleSubmit(title, comment, type, props.id);
        }
        
    }
    
    
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification du rapport'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="type"
                    autoFocus
                    error={nError > 0}
                    helperText={nError > 0 ? 'Nom invalide' : null}
                    value={title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setTitle(event.target.value);
                        setNError(0);
                    }}
                />                   
                <TextField
                    margin="normal"
                    fullWidth
                    id="name"
                    multiline
                    rows={5}
                    label="Commentaire"
                    name="comment"
                    value={comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setComment(event.target.value)
                    }}
                />                
                <FormControl fullWidth>
                <InputLabel id="label"></InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"
                        value={type}
                        label="Type"
                        onChange={(event)=>setType(event.target.value)}
                    >
                    <MenuItem value={4}>Correcte</MenuItem>
                    <MenuItem value={5}>Incorrecte</MenuItem>
                  </Select>
                </FormControl>                             
                {wait ?
                    <Alert severity="info">{props.id===undefined ? "Création en cours" : "Modification en cours"}</Alert>
                    :
                    null
                }    
            
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}