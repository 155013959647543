import * as React from 'react';

import Alert from '@mui/material/Alert'
import AddIcon from "@mui/icons-material/Add"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import { CharismaAssessmentApi } from '../api/assessments/CharismaAssessmentApi.tsx';
import { CharismaEvaluationApi } from '../api/evaluations/CharismaEvaluationApi.tsx';
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'

import CharismaAssessmentDateForm from './assessmentDateForm.tsx'
import CharismaStudentTrainingShuttleForm from './studentTrainingShuttleForm.tsx'
import CharismaStudentTrainingEvaluationForm from './studentTrainingEvaluationForm.tsx'
import CharismaEvaluationForm from './evaluationForm.tsx'

import { CharismaUserContext } from '../context.tsx';
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {formatDate} from '../utils.tsx'
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';



export default function CharismaStudentTrainingPeriod(props){
    
    interface dateChangeInterface{
        id:number,
        evalType:string,
        oldDate:string       
    }


    const [period, setPeriod] = React.useState(null);
    const [evals, setEvals] = React.useState([]);
    const [currentEval, setCurrentEval] = React.useState(null);
    const [currentEvalType, setCurrentEvalType] = React.useState("");
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const [createTrainingSheet, setCreateTrainingSheet] = React.useState(false);
    const [createTutorSheet, setCreateTutorSheet] = React.useState(false);
    const [createExam, setCreateExam] = React.useState(false);    
    const [deleteExams, setDeleteExams] = React.useState(false);
    const [selectedExams, setSelectedExams] = React.useState([]);   
    const [selectedAssessments, setSelectedAssessments] = React.useState([]);   
    const [evaluationProfile, setEvaluationProfile] = React.useState(0);
    const [evaluationType, setEvaluationType] = React.useState(0);
    const [dateData, setDateData] = React.useState(null);
    const openMenu: boolean = Boolean(anchorMenu);

   
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lStudentTrainingId = props.studentTrainingId;
    const lTrainingId = props.trainingId;
    const lPeriodId = props.periodId;
    const lStudentId = props.studentId;   


    React.useEffect(()=>{
        const lResponsePeriods = CharismaTrainingApi.GetTrainingPeriod(lPeriodId)
        lResponsePeriods.then((value)=>{
            if(value.error === 0){
                setPeriod(value.trainingPeriod);
            }
        }).catch((error)=>{
            
        })
    }, [lPeriodId])
    
    const lEvaluationTitle = [
        "Créer une évaluation",
        "Créer un QCM",
        "Créer un devoir écrit",
        "Créer un bilan périodique"
    ]
    
    React.useEffect(()=>{
        if(period !== null){
            const lResponse = CharismaStudentTrainingApi.ListPeriodEvals(period.startDate, period.endDate, props.studentTrainingId)
            lResponse.then((value)=>{
                if(value.error === 0){  
                    let lEvals = value.evals;
                    lEvals = lEvals.sort(function(a,b){
                                return (new Date(a.date)).getTime() - (new Date(b.date)).getTime(); 
                            });
                    setEvals(lEvals);         
                }
            }).catch((error)=>{
                
            })
        }
    }, [period,props.studentTrainingId])    
        
    function handleOpenMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorMenu(event.currentTarget);
    };
    function handleCloseMenu(){
        setAnchorMenu(null);
    };   
    
    function handleSelectedAssessment(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedAssessments.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedAssessments, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedAssessments.slice(1));
            }
            else if(lIndex === selectedAssessments.length - 1){
                lNewSelected = lNewSelected.concat(selectedAssessments.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedAssessments.slice(0, lIndex), selectedAssessments.slice(lIndex + 1),);
            }
        }
        setSelectedAssessments(lNewSelected);
    }
    
    function handleSelectedExam(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedExams.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedExams, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedExams.slice(1));
            }
            else if(lIndex === selectedExams.length - 1){
                lNewSelected = lNewSelected.concat(selectedExams.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedExams.slice(0, lIndex), selectedExams.slice(lIndex + 1),);
            }
        }
        setSelectedExams(lNewSelected);
    }        
    
    function handleSelect(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number, pType : string){
        console.log(pType)
        
        if (pType === "evaluation"){       
            handleSelectedExam(pEvent, pId)
        }
        else{
            handleSelectedAssessment(pEvent, pId)
        }
    }
    
    function handleCreateTutorSheet(pDate : string){
        const lResponse = CharismaAssessmentApi.CreateAssessment(lTrainingId, 3, pDate, lStudentTrainingId, lStudentId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    let lEvals = evals.concat(value.assessment);
                     lEvals = lEvals.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setEvals(lEvals);
                    setCreateTutorSheet(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })        
        
    }
    
    function handleValidChangeDate(pDate : string){
        if(dateData !== null){
            if(dateData.evalType === "assessment"){
                const lResponse = CharismaAssessmentApi.UpdateAssessmentDate(pDate, dateData.id)
                lResponse.then((value)=>{
                if(value.error === 0){
                        if(value.assessment !== null){
                        const lAssessmentId = value.assessment.id;
                        let lEvals = evals.slice();
                        for(let lIndex = 0; lIndex < lEvals.length; lIndex++){
                            if(lEvals[lIndex].id === lAssessmentId && lEvals[lIndex].evalType === "assessment"){
                                lEvals[lIndex].date = value.assessment.date;
                                lEvals = lEvals.sort(function(a,b){
                                    return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                                });
                                setEvals(lEvals);
                                break;
                                
                            }
                        }                  
                    }                
                    setDateData(null);                                          
                }
                else{
                    
                }
                }).catch((error)=>{
                    console.log("catch error")
                })
            } 
            else if(dateData.evalType === "evaluation"){
                const lResponse = CharismaEvaluationApi.UpdateEvaluationDate(pDate, dateData.id)
                lResponse.then((value)=>{
                if(value.error === 0){
                        if(value.evaluation !== null){
                        const lId = value.evaluation.id;
                        let lEvals = evals.slice();
                        for(let lIndex = 0; lIndex < lEvals.length; lIndex++){
                            if(lEvals[lIndex].id === lId && lEvals[lIndex].evalType === "evaluation"){
                                lEvals[lIndex].date = value.evaluation.date;
                                lEvals = lEvals.sort(function(a,b){
                                    return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                                });
                                setEvals(lEvals);
                                break;
                                
                            }
                        }                  
                    }                
                    setDateData(null);                                          
                }
                else{
                    
                }
                }).catch((error)=>{
                    console.log("catch error")
                })
            }               
        }     
    }
    
    function cancelChangeDate(){
        setDateData(null);
    }
    
    
    function cancelCreateTutorSheet(pDate : string){
        setCreateTutorSheet(false);
    }
    
    function handleCreateTrainingSheet(pDate : string){
        const lResponse = CharismaAssessmentApi.CreateAssessment(lTrainingId, 2, pDate, lStudentTrainingId, lStudentId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    let lEvals = evals.concat(value.assessment);
                     lEvals = lEvals.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setEvals(lEvals);
                    setCreateTrainingSheet(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function cancelCreateTrainingSheet(pDate : string){
        setCreateTrainingSheet(false);
    }
    
    
    function handleCreateExam(pDate : string, pTemplateId : number){
        const lResponse = CharismaEvaluationApi.CreateEvaluation(pTemplateId, evaluationType, pDate, lStudentTrainingId, lStudentId, evaluationProfile);

        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.evaluation !== null){
                    let lEvals = evals.concat(value.evaluation);
                     lEvals = lEvals.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setEvals(lEvals);
                    setCreateExam(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })                
    }
    
    function onCreateExam(pProfile: number, pType : number){
        setCreateExam(true);
        setEvaluationProfile(pProfile);
        setEvaluationType(pType);
    }
    
    function handleCancelCreateExam(){
        setCreateExam(false);
        setEvaluationProfile(0);
        setEvaluationType(0);
    }
    
    function handleChangeDate(pId : number, pType : string, pDate:string){
        let lDateChange : dateChangeInterface = {evalType:pType, oldDate:pDate, id:pId};
        setDateData(lDateChange);
    }
            
    function handleEdit(pId : number, pType : string){
        if(pType === "assessment"){
            const lResponse = CharismaAssessmentApi.GetAssessment(pId)
            lResponse.then((value)=>{
                if(value.error === 0){
                    if(value.assessment !== null){
                        setCurrentEval(value.assessment);       
                        setCurrentEvalType(pType);               
                    }
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
            })  
        }       
        else if(pType === "evaluation"){
            const lResponse = CharismaEvaluationApi.GetEvaluation(pId)
            lResponse.then((value)=>{
                if(value.error === 0){
                    if(value.evaluation !== null){
                        setCurrentEval(value.evaluation);
                        setCurrentEvalType(pType);                      
                    }
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
            })            
        }
    }
    
    function handleCancel(){
        setCurrentEval(null);
        setCurrentEvalType("");
    }
    
    function handleDeleteExams(){
        
        if(period !== null){
            const lResponse = CharismaStudentTrainingApi.DeletePeriodEvals(period.startDate, period.endDate, 
                    props.studentTrainingId, selectedExams, selectedAssessments)
            lResponse.then((value)=>{
                if(value.error === 0){  
                    let lEvals = value.evals;
                    lEvals = lEvals.sort(function(a,b){
                                return (new Date(a.date)).getTime() - (new Date(b.date)).getTime(); 
                            });
                    setEvals(lEvals);    
                    setDeleteExams(false);     
                    setSelectedAssessments([]);
                    setSelectedExams([]);
                }
            }).catch((error)=>{
                
            })
        }
        
    }
        
    function handleSubmitEvaluation(pEvaluationId : number, pGlobalNote : number, pGlobalComment: string){
        const lResponse = CharismaEvaluationApi.ValidEvaluation(pEvaluationId, pGlobalNote, pGlobalComment);
        lResponse.then((value)=>{
            if(value.error === 0){                                    
                setCurrentEval(null);
                setCurrentEvalType("");        
                const lId = value.id;
                const lNote = value.note;
                let lEvals = evals.slice();
                for(let lIndex = 0; lIndex < lEvals.length; lIndex++){
                    let lEval = lEvals[lIndex];
                    if(lEval.id === lId && lEval.evalType === "evaluation"){
                        lEval.globalNote = lNote;
                        break;
                    }
                }   
                setEvals(lEvals);  
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })         
    }        
    
    function submitShuttle(pItems){
        const lResponse = CharismaAssessmentApi.UpdateAssessmentNotes(pItems, "", 0, 0)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){                                       
                    setCurrentEval(null);
                    setCurrentEvalType("");
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })  
    }
    
    function getEvaluationColor(pEvaluation){
        let lColor = "";
        let lSignatoryDate = null;
        if(pEvaluation.evalType === "evaluation"){
            const signatories = pEvaluation.signatories;
            if(pEvaluation.evalType === "evaluation" && signatories !== undefined){                        
                for(let lIndex = 0; lIndex < signatories.length; lIndex++){
                    const lSignatory = signatories[lIndex];
                    if(lSignatory.date !== null){
                        lSignatoryDate = lSignatory.date;
                        break;
                    }
                }
            }
            const refDate = Date.parse("2025-01-14");
            
            if(lSignatoryDate === null){
                pEvaluation.globalNote >=4 ? lColor = "green" : pEvaluation.globalNote===3 ? lColor="orange" : pEvaluation.globalNote >=1 ? lColor = "red" : lColor = "pink";
            } 
            else{
                const lEvaluationDate = Date.parse(lSignatoryDate);
                if(lEvaluationDate > refDate){
                    pEvaluation.globalNote >=4 ? lColor = "green" : pEvaluation.globalNote===3 ? lColor="orange" : pEvaluation.globalNote >=1 ? lColor = "red" : lColor = "pink";
                }
                else{
                    pEvaluation.globalNote >=2 ? lColor = "green" : pEvaluation.globalNote>0 ? lColor = "red" : lColor = "pink";
                }
            }
        }
        return lColor;        
    }    
    
    function getFillBy(pEvaluation){        
        if(pEvaluation.evalType === "evaluation"){
            if(pEvaluation.type === 0){
                if(pEvaluation.profile === 1){
                    return "Formateur";
                }
                else if(pEvaluation.profile === 2){
                    return "Tuteur";
                }
            }
            else if(pEvaluation.type === 1){
                return "Stagiaire";
            }
            else if(pEvaluation.type === 2){
                return "Stagiaire";
            }
            else if(pEvaluation.type === 3){
                if(pEvaluation.profile === 1){
                    return "Stagiaire/Formateur";
                }
                else if(pEvaluation.profile === 2){
                    return "Stagiaire/Tuteur";
                }
            }
        }
        return "";
    }
    
    function getStatus(pEvaluation){        
        if(pEvaluation.evalType === "evaluation"){
            if(pEvaluation.type === 0){
                if(pEvaluation.profile === 1){
                    return getProfileStatus(pEvaluation, 1);
                }
                else if(pEvaluation.profile === 2){
                    return getProfileStatus(pEvaluation, 2);
                }
            }
            else if(pEvaluation.type === 1){
                getProfileStatus(pEvaluation, 3);
            }
            else if(pEvaluation.type === 2){
                getProfileStatus(pEvaluation, 3);
            }
            else if(pEvaluation.type === 3){
                if(pEvaluation.profile === 1){
                    return getProfileStatus(pEvaluation, 3) + "/" + getProfileStatus(pEvaluation, 1);
                }
                else if(pEvaluation.profile === 2){
                    return getProfileStatus(pEvaluation, 3) + "/" + getProfileStatus(pEvaluation, 2);
                }
            }
        }
        return "";
    }    
    
    function getProfileStatus(pEvaluation, pProfile){
        const signatories = pEvaluation.signatories;
        if(pEvaluation.evalType === "evaluation" && signatories !== undefined){                        
            for(let lIndex = 0; lIndex < signatories.length; lIndex++){
                const lSignatory = signatories[lIndex];
                if(lSignatory.profile === pProfile){
                    if(lSignatory.date === null){
                        return "A remplir";
                    }
                    else{
                        return "Fait";
                    }
                }
            }
        }
        return "A remplir";
    }
    
    return(
        
        <Box>   
            {
                period !== null ?
                <Card>
                    <CardHeader
                        title={period.name}
                        subheader={"du " + formatDate(period.startDate) + " au " + formatDate(period.endDate) }
                        action={
                            <div>
                            {lReadWrite ?
                            <IconButton onClick={(event)=>handleOpenMenu(event)}>
                                <AddIcon/>
                            </IconButton>
                            :
                            null}
                            {selectedAssessments.length > 0 || selectedExams.length > 0?
                                    <IconButton onClick={()=>setDeleteExams(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                            </div>
                        }
                    />
                    <CardContent>
                        <TableContainer overflowX='auto'>
                        <Table>
                            <TableHead>
                                <TableRow key="title">
                                    {lReadWrite ?
                                        <TableCell padding='checkbox'/>
                                        :
                                        null
                                    }
                                    <TableCell>
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Type
                                    </TableCell>
                                    <TableCell>
                                        A remplir par
                                    </TableCell>
                                    <TableCell>
                                        Statut
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    evals.map((lEval)=>(
                                        <TableRow key={lEval.id} sx={{background:getEvaluationColor(lEval)}}>
                                            {lReadWrite ?
                                            <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                        lEval.evalType === "evaluation" ? 
                                                            selectedExams.indexOf(lEval.id) >= 0 
                                                            :
                                                            selectedAssessments.indexOf(lEval.id) >= 0
                                                        }
                                                onClick={                                    
                                                (event)=>handleSelect(event, lEval.id, lEval.evalType)
                                                }
                                            />
                                            </TableCell>
                                                :
                                                null
                                            }
                                            <TableCell>
                                                {formatDate(lEval.date)}
                                                {(lCurrentUser.profile === 0 && lEval.evalType === "evaluation") ||
                                                 lEval.evalType === "assessment" 
                                                    ?
                                                    <IconButton onClick={()=>handleChangeDate(lEval.id, lEval.evalType, lEval.date)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    :
                                                    null
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {lEval.title}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    getFillBy(lEval)
                                                }
                                            </TableCell>                                            
                                            <TableCell>
                                                {                                                    
                                                    getStatus(lEval)
                                                }
                                            </TableCell>  
                                            <TableCell>
                                                <IconButton onClick={()=>handleEdit(lEval.id, lEval.evalType)}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton component={Link} to={global.api + "/evaluations/printEvaluation.php?id="+lEval.id} target="_blank" rel="noopener noreferrer">
                                                    <PictureAsPdfIcon/>
                                                </IconButton>   
                                            </TableCell>                                            
                                        </TableRow>    
                                    ))
                                }
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                :
                null
            }   
            <Menu
                open={openMenu}
                anchorEl={anchorMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
            >
                <MenuItem
                    onClick={()=>onCreateExam(1, 0)}
                >
                    Ajouter une évaluation OF
                </MenuItem>
                <MenuItem
                    onClick={()=>onCreateExam(2, 0)}
                >
                    Ajouter une évaluation Tuteur
                </MenuItem>
                {props.trainingType === 2 ?
                    <MenuItem
                        onClick={()=>onCreateExam(1,3)}
                    >
                        Ajouter un bilan périodique OF
                    </MenuItem>
                    :
                    null
                }
                {props.trainingType === 2 ?
                    <MenuItem
                        onClick={()=>onCreateExam(2,3)}
                    >
                        Ajouter un bilan périodique Tuteur
                    </MenuItem>
                    :
                    null
                }
                <MenuItem
                    onClick={()=>onCreateExam(3,1)}
                >
                    Ajouter un QCM
                </MenuItem>
                <MenuItem
                    onClick={()=>onCreateExam(3,2)}
                >
                    Ajouter un devoir
                </MenuItem>
                <MenuItem
                    onClick={()=>setCreateTrainingSheet(true)}
                >
                    Ajouter  une fiche navette OF
                </MenuItem>
                <MenuItem
                    onClick={()=>setCreateTutorSheet(true)}
                >
                    Ajouter  une fiche navette Tutorat
                </MenuItem>
            </Menu>  
            {
                dateData !== null ?
                <CharismaAssessmentDateForm
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    title="Changer la date"
                    handleSubmit= {handleValidChangeDate}
                    handleCancel={cancelChangeDate}
                 />
                 :
                 null                
            }
            {createTrainingSheet ?
                <CharismaAssessmentDateForm
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    handleSubmit= {handleCreateTrainingSheet}
                    handleCancel={cancelCreateTrainingSheet}
                 />
                 :
                 null
             }
             {createTutorSheet ?
                <CharismaAssessmentDateForm
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    handleSubmit= {handleCreateTutorSheet}
                    handleCancel={cancelCreateTutorSheet}
                 />
                 :
                 null
             }
             {createExam ?
                <CharismaEvaluationForm
                    title = {lEvaluationTitle[evaluationType]}
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    evals={
                        evaluationType ===  0 && evaluationProfile === 1 ? 
                            props.exams 
                            :
                        evaluationType ===  0 && evaluationProfile === 2 ? 
                            props.tutorExams
                            :
                        evaluationType ===  1 && evaluationProfile === 3 ? 
                            props.qcms
                            :
                        evaluationType ===  2 && evaluationProfile === 3 ?
                            props.homeworks
                            :
                        evaluationType ===  3 && evaluationProfile === 1 ?
                            props.periodReportsOf 
                            :
                        evaluationType ===  3 && evaluationProfile === 2 ?
                            props.periodReportsTutor
                         :
                         null                      
                    }
                    handleCancel={handleCancelCreateExam}
                    handleSubmit={handleCreateExam}
                />
                :
                null
             }             
             {currentEval !== null && currentEvalType === "assessment" ?
                <CharismaStudentTrainingShuttleForm
                    assessment={currentEval}
                    id={currentEval.id}
                    handleCancel={handleCancel}
                    handleSubmit={submitShuttle}
                    userProfile = {lCurrentUser.profile}
                    type = {currentEval.type}
                    readOnly = {!((lCurrentUser.profile === 0 || lCurrentUser.profile === 1) && currentEval.type === 2 || 
                                  (lCurrentUser.profile === 2 && currentEval.type === 3) ||
                                  lCurrentUser.profile === 3)}
                />
                :
                null
             }
             {currentEval !== null && currentEvalType === "evaluation" ?
                <CharismaStudentTrainingEvaluationForm
                    evaluation={currentEval}
                    id={currentEval.id}
                    userProfile={lCurrentUser.profile}
                    handleCancel={handleCancel}             
                    handleSubmit={handleSubmitEvaluation}       
  
                />
                :
                null
             }
            <Dialog
                open={deleteExams}
            >
                <DialogTitle>Suppression d'evaluations</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer définitivement les évaluation sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteExams(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteExams}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>                   
        </Box>      
    );
    
    
}