import * as React from 'react';

import CharismaAdminMainMenu from './menus/adminMenu.tsx';
import CharismaStudentMainMenu from './menus/studentMenu.tsx';
import CharismaTeacherMainMenu from './menus/teacherMenu.tsx';
import CharismaTutorMainMenu from './menus/tutorMenu.tsx';
import CharismaExaminatorMainMenu from './menus/examinatorMenu.tsx';
import CharismaDocumentsManager from './documents/documents.tsx';
import CharismaAssessmentTemplatesList from './assessmentTemplates/assessmentTemplatesList.tsx';
import CharismaEvaluationTemplatesList from './evaluationTemplates/evaluationTemplatesList.tsx';
import CharismaFinancalsList from './financals/financalsList.tsx';
import CharismaStudentTrainingSpace from './studentTrainings/studentTrainingSpace.tsx';
import CharismaSettings from './settings/settings.tsx';
import CharismaSignIn from './user/SignIn.tsx';
import { CharismaUserApi } from './api/users/CharismaUserApi.tsx';
import { CharismaUserContext } from './context.tsx';
import CharismaUserDocument from './user/userDocuments.tsx'
import CharismaUserProfile from './user/userProfile.tsx';
import CharismaUsersList from './user/usersList.tsx';
import CharismaUserDetails from './user/userDetails.tsx';
import CharismaTraining from './training/training.tsx';
import CharismaTrainingsList from './training/trainingsList.tsx';
import Grid from '@mui/material/Grid'

import {BrowserRouter, Routes, Route } from "react-router-dom";
import './globals.js'
import CharismaWelcomeStudent from './welcome/welcomeStudent.tsx';
import CharismaWelcomeTeacher from './welcome/welcomeTeacher.tsx';
import CharismaWelcomeTutor from './welcome/welcomeTutor.tsx';
import CharismaWelcomeExaminator from './welcome/welcomeExaminator.tsx';



function CharismaMain() {
    

    
    const [user, setUser] = React.useState(null);
    
    React.useEffect(()=>{
        const lResponse = CharismaUserApi.CheckConnected();
        lResponse.then((value)=>{
            if(value.error === 0){
                if(user === null){
                    setUser(value.user);
                }
            }
            else{
                setUser(null);
            }
        }).catch((error)=>{
            
        })
    })
    function handleDisconnect(){
        setUser(null);
    }
    return (
        <CharismaUserContext.Provider value={user}>
            {user === null?
                <CharismaSignIn
                    setUser={setUser}
                />
                :
                <div>
                <BrowserRouter>
                    {user.profile === 0 ?
                        <CharismaAdminMainMenu
                            handleDisconnect={handleDisconnect}
                        />
                        :
                        null
                    }
                    {user.profile === 1 ?
                        <CharismaTeacherMainMenu
                            handleDisconnect={handleDisconnect}
                        />
                        :
                        null
                    }
                    {user.profile === 2 ?
                        <CharismaTutorMainMenu
                            handleDisconnect={handleDisconnect}
                        />
                        :
                        null
                    }                    
                    {user.profile === 3 ?
                        <CharismaStudentMainMenu
                            handleDisconnect={handleDisconnect}
                        />
                        :
                        null
                    }
                    {user.profile === 4 ?
                        <CharismaExaminatorMainMenu
                            handleDisconnect={handleDisconnect}
                        />
                        :
                        null
                    }
                    <Routes>
                        <Route 
                            path="/"
                            element = {
                                <div>
                                {user.profile === 0?
                                    <CharismaWelcomeTeacher
                                        user={user}
                                    />
                                    :
                                    null
                                }
                                {user.profile === 1?
                                    <CharismaWelcomeTeacher
                                        user={user}
                                    />
                                    :
                                    null
                                }
                                {user.profile === 2?
                                    <CharismaWelcomeTutor
                                        tutorId={user.id}
                                    />
                                    :
                                    null
                                }                                
                                {user.profile === 3?
                                    <CharismaWelcomeStudent
                                        studentId={user.id}
                                    />
                                    :
                                    null
                                }
                                {user.profile === 4?
                                    <CharismaWelcomeExaminator
                                        examinatorId={user.id}
                                    />
                                    :
                                    null
                                }
                                </div>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <CharismaUserProfile
                                    user={user}
                                    userProfile={user.profile}
                                />
                            }
                        />
                        <Route
                            path="/user/:userProfile/:userId"
                            element={
                                <CharismaUserDetails
                                />
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <CharismaUsersList
                                    title={'Liste des utilisateurs'}
                                />
                            }
                        />
                        <Route
                            path="/teachers"
                            element={
                                <CharismaUsersList
                                    title={'Liste des Formateurs'}
                                    profile={1}
                                />
                            }
                        />
                        <Route
                            path="/tutors"
                            element={
                                <CharismaUsersList
                                    title={'Liste des Tuteurs'}
                                    profile={2}
                                />
                            }
                        /> 
                        <Route
                            path="/students"
                            element={
                                <CharismaUsersList
                                    title={'Liste des Stagiaires'}
                                    profile={3}
                                />
                            }
                        />   
                        <Route
                            path="/users-trash"
                            element={
                                <CharismaUsersList
                                    title={'Corbeille des utilisateurs'}
                                    trash={true}
                                />
                            }
                        />  
                        <Route
                            path="/user-documents"
                            element={
                                <CharismaUserDocument/>
                            }
                        />    
                        <Route
                            path="/trainings"
                            element={
                                <CharismaTrainingsList
                                    title={'Formations en Cours'}
                                />
                            }
                        />  
                        <Route
                            path="/trainings-trash"
                            element={
                                <CharismaTrainingsList
                                    title={'Corbeille des formations'}
                                    trash={1}
                                />
                            }
                        />  
                        <Route
                            path="/student/:studentId/training/:studentTrainingId"
                            element={
                                <CharismaStudentTrainingSpace />
                            }
                        />                           
                        <Route
                            path="/training/:trainingId"
                            element={
                                <div>
                                    {
                                        user.profile === 0 || user.profile === 1?
                                            <CharismaTraining />
                                        :
                                        user.profile === 3 ?
                                            <CharismaWelcomeStudent
                                                studentId={user.id}
                                            />
                                        :
                                        null
                                    }
                                
                                </div>
                            }
                        />  
                        <Route
                            path="/training/:trainingId/:page"
                            element={
                                user.profile === 3 ?
                                    <CharismaWelcomeStudent
                                        studentId={user.id}
                                    />
                                    :
                                    null
                            }
                        />
                        <Route
                            path="/studentTraining/:trainingId/:page"
                            element={
                                user.profile === 2 ?
                                    <CharismaWelcomeTutor
                                        tutorId={user.id}
                                    />
                                    :
                                    null
                            }
                        />                          
                        <Route
                            path="/financals"
                            element={
                                <CharismaFinancalsList/>
                            }
                        />                                                              
                        <Route
                            path="/daily-assessments"
                            element={
                                <CharismaAssessmentTemplatesList
                                    title={"Gestion des Bilans Quotidiens"}
                                    type = {0}
                                />
                                }
                        />
                        <Route
                            path="/monthly-assessments"
                            element={
                                <CharismaAssessmentTemplatesList
                                    title={"Gestion des Bilans Mensuels"}
                                    type = {1}
                                />
                            }
                        />
                        <Route
                            path="/shuttles"
                            element={
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={12}>
                                        <CharismaAssessmentTemplatesList
                                            title={"Fiches Navettes Formateurs"}
                                            type = {2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <CharismaAssessmentTemplatesList
                                            title={"Fiches Navettes Tuteurs"}
                                            type = {3}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        />
                        <Route
                            path="/visits"
                            element={
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={12}>
                                        <CharismaAssessmentTemplatesList
                                            title={"Visites Tuteurs"}
                                            type = {4}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <CharismaAssessmentTemplatesList
                                            title={"Visites de Régulation"}
                                            type = {5}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        />
                        <Route
                            path="/exam-templates"
                            element={
                                <CharismaEvaluationTemplatesList
                                    title={"Examens"}
                                    type = {0}
                                />
                            }
                        />
                        <Route
                            path="/qcm-templates"
                            element={
                                <CharismaEvaluationTemplatesList
                                    title={"QCM"}
                                    type = {1}
                                />
                            }
                        />
                        <Route
                            path="/homework-templates"
                            element={
                                <CharismaEvaluationTemplatesList
                                    title={"Devoirs"}
                                    type = {2}
                                />
                            }
                        />
                        <Route
                            path="/periodic-templates"
                            element={
                                <CharismaEvaluationTemplatesList
                                    title={"Bilans Périodiques AE"}
                                    type = {3}
                                />
                            }
                        />
                        <Route
                            path="/documents/*"
                            element={
                                <CharismaDocumentsManager/>
                            }
                        />
                        <Route
                            path="/settings/*"
                            element={
                                <CharismaSettings/>
                            }
                        />
                    </Routes>
                </BrowserRouter>
                </div>
            }
        </CharismaUserContext.Provider>
    );
}


export default CharismaMain;
