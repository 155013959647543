import * as React from 'react';

import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import {Link} from "react-router-dom"



import { CharismaUserContext } from '../context.tsx';

export default function CharismaTrainingListItem(props){
    

    const [course, setCourse] = React.useState(props.course);
    const [running, setRunning] = React.useState(props.running);
    const [reason, setReason] = React.useState(props.reason);
    const [training, setTraining] = React.useState(props.training);
    const [subscribe, setSubscribe] = React.useState(props.subscribe);
    const [tutor, setTutor] = React.useState(props.tutor);
    

    
    React.useEffect(()=>{

        setReason(props.reason);
        setRunning(props.running)
        setTraining(props.training);
        setTutor(props.tutor);    
        setCourse(props.course);
        setSubscribe(props.subscribe);
    }, [props.reason, props.running, props.training, props.tutor, props.course, props.subscribe])
    

    
    const lReasons=["En Cours","Certifié", "Abandon", "Echec"]
    
    const lSubscribes =["Incomplet", "A Valider", "Valide"];

    return(               
        <ListItem 
            alignItems='flex-start'
            button

            sx={{background:props.selected ? "lightgray" : null, minWidth:300}}

            component={props.onSelect === undefined ? Link : null}
            to={props.onSelect === undefined ? "/training/" + props.id + '/book' : null}            
            onClick={()=>props.onSelect(props.training.id,props.id)}
            

        >

            <ListItemText
                primary={training.training}
                secondary={
                    <Box>
                    <Typography>{"Tuteur : " + tutor.firstName + ' ' + tutor.lastName}</Typography>
                    <Typography>{"Parcours : " + course.course}</Typography>
                    <Typography>{"Inscription : " + lSubscribes[subscribe]}</Typography>
                        {running ?
                            <Typography>Statut : En Cours</Typography>
                            :
                            <Typography>{"Statut : " + lReasons[reason]}</Typography>
                        }
                                             
                    </Box>
                }
            />            
        </ListItem>                                
    );
    
    
}