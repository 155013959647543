import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CharismaStudentTrainingQcmFormQuestion from './studentTrainingQcmFormQuestion.tsx'
import CharismaStudentTrainingHomeworkFormQuestion from './studentTrainingHomeworkFormQuestion.tsx'
import Typography from '@mui/material/Typography'

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';




export default function CharismaStudentTrainingQcmFormSection(props){

       
   
    
    function createQuestions(pItem){
 
        return(
            <Box sx={{m:'10px'}}>
                <Typography>
                    <Box fontWeight="fontWeightBold" sx={{mb:'10px'}}>
                    {pItem.title}
                    </Box>
                </Typography>
                {props.evalType === 1?
                    <CharismaStudentTrainingQcmFormQuestion
                        items={pItem.items}
                        handleChangeNote={props.handleChangeNote}
                        readOnly={props.readOnly}
                    />
                    :
                 props.evalType === 2?
                 <CharismaStudentTrainingHomeworkFormQuestion
                        studentComment={pItem.studentComment}
                        itemId={pItem.id}
                        handleChangeComment={props.handleChangeComment}
                        readOnly={props.readOnly}
                    />
                    :
                    null
                }
                    
            </Box>
        )
    }
        
    function getNote(pItem){
        let lNote = 0;
        if(pItem.max > 0){
            if(pItem.note === 1){
                lNote = 1;
            }
            else{
                lNote = 2;
            }    
        }
        return lNote;
    }
                                       
    return(
            <Card>
                <CardHeader

                    title={props.section.title}
                    subheader={props.section.info}
                />
                <CardContent>
                {
                    props.section.items.map((item)=>(
                        <Box sx={{mt:'5px', background:"#f1f1f1"}}>
                            {getNote(item) === 1 ?                    
                                <Avatar sx={{ bgcolor: "green"}} aria-label="recipe">
                                    <ThumbUpOffAltIcon/>
                                </Avatar>:
                             getNote(item) ===  2 ?
                                <Avatar sx={{ bgcolor: "red"}} aria-label="recipe">
                                    <ThumbDownOffAltIcon/>
                                </Avatar>:
                                null
                            }
                    
                            {createQuestions(item)}
                        </Box>
                        ))
                }
                </CardContent>
            </Card>      
    );
}