import * as React from 'react';

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { CharismaEvaluationApi } from '../api/evaluations/CharismaEvaluationApi.tsx';
import CharismaStudentTrainingEvaluationFormItem from './studentTrainingEvaluationFormItem.tsx'
import CharismaStudentTrainingEvaluationFormSection from './studentTrainingEvaluationFormSection.tsx'
import CharismaStudentTrainingPeriodicFormSection from './studentTrainingPeriodicFormSection.tsx'
import CharismaStudentTrainingQcmFormSection from './studentTrainingQcmFormSection.tsx'
import CharismaRating from '../components/CharismaRating.tsx'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {formatDate} from '../utils.tsx'

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


export default function CharismaStudentTrainingEvaluationForm(props){
           
    const [wait, setWait] = React.useState(false);
    const [globalNote, setGlobalNote] = React.useState(0);
    const [globalComment, setGlobalComment] = React.useState("");
    const [notes, setNotes] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [readOnly, setReadOnly] = React.useState(false);
    const [readOnlyItems, setReadOnlyItems] = React.useState(false);
    const [missing, setMissing] = React.useState(false);
    
    
    React.useEffect(()=>{
        let lReadOnly = props.evaluation.signedByProfile.indexOf(props.userProfile) > -1;
        let lReadOnlyItems = props.evaluation.signedByProfile.indexOf(props.userProfile) > -1;
        if(props.evaluation.type === 1){
            lReadOnly = props.userProfile !== 3 ;            
            lReadOnlyItems = props.userProfile !== 3;
            if(props.userProfile === 3){
                lReadOnly = props.evaluation.signedByProfile.indexOf(3) > -1;
                lReadOnlyItems = props.evaluation.signedByProfile.indexOf(3) > -1;
            }
        }
        else if(props.evaluation.type ===2){
            lReadOnly = props.userProfile !== 3 && props.userProfile !== 0 && props.userProfile !== 1;            
            lReadOnlyItems = props.userProfile !== 3;
            if(props.userProfile === 3){
                lReadOnly = props.evaluation.signedByProfile.indexOf(3) > -1;
                lReadOnlyItems = props.evaluation.signedByProfile.indexOf(3) > -1;
            }
            
        }
        else if(props.evaluation.type ===3){
            lReadOnly = (props.evaluation.profile === 2 && props.userProfile !== 2) ||
                        (props.evaluation.profile === 1 && props.userProfile > 1);
            lReadOnlyItems = lReadOnly;
            if(props.userProfile === 3){
                lReadOnly = false;
                lReadOnlyItems = false;
            }
            
        }
        else{
            lReadOnly = (props.evaluation.profile === 2 && props.userProfile !== 2) ||
                        (props.evaluation.profile === 1 && props.userProfile > 1);
            lReadOnlyItems = lReadOnly;
        }

        setGlobalComment(props.evaluation.globalComment);
        if(props.evaluation.type === 2){
            setGlobalNote(props.evaluation.globalNote);
        }        
        setReadOnly(lReadOnly);
        setReadOnlyItems(lReadOnlyItems);
    }, [props.userProfile, props.evaluation])
    
    function checkItems(items, parentType){
        let lResult = true;
        let lNb = 0;
        console.log(items.length);
        for (let index = 0; index < items.length && lResult; index++){
            let type = items[index].type;
            let note = items[index].note;
            if(type === 4 || type === 5){
                if(note > 0){
                    lNb = lNb + 1;
                }
            }                       
            else if(type === 1 || type === 2){
                lResult = note > 0;
            }
            else if(items[index].items !== undefined){
                lResult = checkItems(items[index].items, type);
            }
            
        }
        if(parentType === 3){
            lResult = lNb > 0;
        }
        
        return lResult;
    }
    
    function handleChangeNote(pNote: number, pId : number){
        let lNotes = notes.slice();
        const lTuple = {id:pId, note:pNote};
        let lFound = false;
        for(let lIndex = 0; lIndex < lNotes.length && !lFound; lIndex++){
            let lNote = lNotes[lIndex];
            if(lNote.id === pId){
                lNote.note = pNote;
                lFound = true;
            }
        }
        if(!lFound){
            lNotes.push(lTuple);
        }
        const lResponse = CharismaEvaluationApi.UpdateEvaluationSingleItem(pId, pNote)
            lResponse.then((value)=>{
                if(value.error === 0){
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
        })
        setNotes(lNotes);
    }
    
    function createGlobal(pReadOnly:boolean){
        return(
          <Box sx={{m:'30px'}}>
             <Box display='flex' sx={{m:'10px'}}>  
             <Typography>Note globale</Typography>
             <CharismaRating
                value={globalNote}
                onChange={setGlobalNote}
                readOnly={pReadOnly}
                max={5}
             />
             </Box>
            <TextField
                id="partComment"
                name="partComment"
                fullWidth
                multiline
                label="Commentaire global"
                value={globalComment}
                rows={5}    
                disabled={pReadOnly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGlobalComment(event.target.value);
                }}     
            />             
          </Box>  
        );
    }
    
    function handleChangeComment(pComment: string, pId : number, pField : string){
        let lComments = comments.slice();
        const lTuple = {id:pId, comment:pComment};
        let lFound = false;
        for(let lIndex = 0; lIndex < lComments.length && !lFound; lIndex++){
            let lComment = lComments[lIndex];
            if(lComment.id === pId){
                lComment.comment = pComment;
                lFound = true;
            }
        }
        if(!lFound){
            lComments.push(lTuple);
        }
        const lResponse = CharismaEvaluationApi.UpdateEvaluationSingleCommentItem(pId, pComment, pField)
            lResponse.then((value)=>{
                if(value.error === 0){
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
        })
        setComments(lComments);    
            
    }
   
    function handleSubmit(){
        let lMissing = checkItems(props.evaluation.items, 0);
        setMissing(false);
        if(!lMissing){
            setMissing(true);    
        }
        else{
            props.handleSubmit(props.evaluation.id, globalNote, globalComment);
            setWait(true);
        }
    }
    
                          
    return(
        <Dialog
            open={true}
            fullWidth
            maxWidth={'lg'}
        >            
            <DialogTitle>
            <Typography variant="h5">{props.evaluation.title}</Typography>
            {
                props.evaluation.type === 1 && props.evaluation.globalNote >= 0 ?
                    <CharismaRating
                        value={props.evaluation.globalNote}
                        readOnly={true}
                        max={5}
                    />:
                    null
                
            }
            {props.evaluation.signed ?
                <Typography>{"Validé le " + formatDate(props.evaluation.signedDate) +" par " + props.evaluation.signedBy}</Typography>
                :
                null
            }
            </DialogTitle>    
            <DialogContent>         
                {
                    props.evaluation.items.map((item)=>(
                        item.type === 0 && props.evaluation.type === 0?
                            <CharismaStudentTrainingEvaluationFormSection
                                section = {item}
                                handleChangeNote={handleChangeNote}
                                handleSetComment={handleChangeComment}
                                evalType = {props.evaluation.type}
                                readOnly = {readOnly}
                            />
                            :
                        item.type === 0 && (props.evaluation.type === 1 || props.evaluation.type === 2) ?
                            <CharismaStudentTrainingQcmFormSection
                                section = {item}
                                handleChangeNote={handleChangeNote}
                                handleChangeComment={handleChangeComment}
                                evalType = {props.evaluation.type}
                                readOnly = {readOnlyItems}
                            />
                            :
                        item.type !== 0 && props.evaluation.type === 0?
                            <div>
                            <Typography>{item.title}</Typography>
                            <CharismaStudentTrainingEvaluationFormItem
                                item = {item}
                                handleSetValue = {handleChangeNote}
                                readOnly = {readOnlyItems}
                            />
                            </div>
                           :
                        item.type === 0 && props.evaluation.type === 3?
                            <CharismaStudentTrainingPeriodicFormSection
                                section = {item}
                                handleChangeNote={handleChangeNote}
                                handleSetComment={handleChangeComment}
                                evalType = {props.evaluation.type}
                                profile = {props.evaluation.profile}
                                userProfile = {props.userProfile}
                                readOnly = {readOnly}                                
                            />
                        :
                        null
                    ))
                }
                {props.evaluation.type === 2 ?
                    createGlobal(props.userProfile > 1 || props.evaluation.signed)
                    :
                    null
                }

            </DialogContent>
            <DialogActions>
                {missing ?
                    <Alert severity="error">{"Certains items ne sont pas remplis"}</Alert>
                    :
                    null
                }
                {wait ?
                    <Alert severity="info">{"Validation en cours"}</Alert>
                    :
                    null
                }            
                    {readOnly ?
                    <Button onClick={()=>props.handleCancel()}>OK</Button>  
                    :
                    <div>
                        <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
                        <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>                                        
                    </div>    
                    }         
            </DialogActions>
        </Dialog>        
    );
}