import * as React from 'react';


import Box from '@mui/material/Box'



import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaExamApi } from '../api/exams/CharismaExamApi.tsx';

import EditIcon from '@mui/icons-material/Edit'
import {formatDate} from '../utils.tsx'

import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';





export default function CharismaExaminatorExamsList(props){
    
    interface exam{
        date:string,
        title:string,
        template:string,
        id:number
    }
    
    const [examsList, setExamsList] = React.useState([]);
    const [currentExam, setCurrentExam] = React.useState(null);
    


    React.useEffect(()=>{
        const lResponseExams = CharismaExamApi.ListExamsForExaminator(props.examinatorId);
        lResponseExams.then((value)=>{
            if(value.error === 0){
                setExamsList(value.exams);
            }
        }).catch((error)=>{
            
        })   

    }, [props.examinatorId])
    
   

    
    function handleEdit(pId : number){
        const lResponse = CharismaExampApi.GetExam(pId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCurrentExam(value.exam);
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })  
    }
    
  
    
    function createExamsTable(pExams : exam[]){
        return(
            pExams.map((exam)=>(
                <TableRow key={exam.id}>                    
                    <TableCell>
                        {exam.title}
                    </TableCell>
                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                        {formatDate(exam.date)}
                    </TableCell>
                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                        {formatDate(exam.template)}
                    </TableCell>
                    <TableCell padding="checkbox">
                        <IconButton
                            onClick={()=>handleEdit(exam.id)}
                        >
                            <EditIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
                 
            ))
        )
    }
    
    
    return(
        
        <Box>
            <Card>
                <CardHeader
                    title="Sessions d'examens"
                />
                <CardContent>
                    <Table>                               
                        <TableHead>
                            <TableRow>

                                <TableCell>Nom</TableCell>
                                <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Date</TableCell>
                                <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Examen</TableCell>
                                <TableCell padding="checkbox">
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            createExamsTable(examsList)
                        }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
             
        </Box>      
    );
    
    
}